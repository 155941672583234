<div class="bg-white  shadow-custom" style="border-radius: 17px;">

    <form class="px-4 py-4 mt-3 " >

        <div class="col-10 my-3">
            <span>Rechercher un utilisateur <span class="text-danger">*</span></span>

            <div class="d-flex ">
                <div class="col-5">
                    <input [(ngModel)]="motclef" name="motclef" (ngModelChange)="filterUser()" type="text" maxlength="35"
                    class="form-control">
                    
                <ul *ngIf="motclef.length > 0" class="col-3 list-group mt-2 position-absolute list-user-hover shadow-custom"
                    style="height: 40vh; overflow-y: scroll;">
                    <li *ngFor="let user of filterUser()" (click)="selectUser(user)" class="list-group-item"
                        style="background-color:#fff; color: #000;">
                        {{ user.username }}
                        <p class="mt-1 mb-0" style="font-size: 12px;">{{ user ? user.prenom : "" }} {{
                            user ? user.nom : "" }}</p>
                    </li>
                </ul>
                </div>
    
                <div class="ms-3 d-flex" *ngIf="user">
                   <div class="me-3">username : </div> <b>{{ user ? user?.username : "" }}</b>  <div class="mx-3">prenom : </div>  <b>{{ user ? user.prenom : "" }} </b> <div class="mx-3"> nom : </div> <b>{{ user ? user.nom : "" }}</b>
                </div>
            </div>

        </div>

        <p *ngIf="user" class="my-2 message-error ms-3">
            {{ message }}
        </p>


        <div *ngIf="user" class="d-flex">
    
            <p class="my-3 me-2 d-flex" *ngFor="let application of applicationList">
                <button class="bold"
                    [class]=" idApplicationActive == application.id ? ' btn btn-outline-success  py-2 px-3 rounded-2 active' : ' btn btn-outline-success  py-2 px-3 rounded-2 ' "
                    (click)="getModuleByIdApplication(application.id)"> {{ application.application }}
                </button>
            </p>
        </div>


        <div *ngIf="modules.length > 0 && user" class="position-relative">

            <table class="table table-bordered">
                <thead class="">
                    <tr class="table-audev">

                        <th class="align-middle "></th>
                        <th class="align-middle" *ngFor="let module of modules">{{ module.module }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let role of roles;" class="position-relative">

                        <th class="align-middle list-granting-access " style="cursor:pointer;">
                            {{role.role}}
                            <div class="tooltip-bloc" style="z-index: 9999;">
                                <ul class="custom-tooltip p-4 btn-custom-primary tooltip-container border text-white position-absolute">
                                    <fa-icon [icon]="faCaretLeft" class="fa-2x caret text-secondary-custom"></fa-icon>
                                    <li *ngFor="let privilege of role.privileges"> {{ privilege.privilege }} </li>
                                </ul>
                            </div>
                        </th>

                        <td class="align-middle" *ngFor="let module of modules; index as i">
                            <div class="form-check">
                                <input class="form-check-input pointer" type="checkbox" [value]="role.id" [name]="module.id+ ' ' +role.id+' '+i"
                                    (change)="addAccess(role, module, $event)" [id]="module.id+ ' ' +role.id">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="mt-5">
                <button type="submit" class="btn btn-custom-primary text-white rounded-3  me-3" (click)="validation()">
                    {{ isUpdate == false ? 'Enreristrer' : "Modifier" }}
                </button>
                <button class="btn btn-secondary rounded-3">
                    Annuler
                </button>
            </div>
        </div>
      

    </form>

