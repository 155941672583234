import { Injectable } from "@angular/core"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import { Subject } from "rxjs";
import { LoadingComponent } from "../components/dialogs/utilities/loading-component/loading-component.component";
import { IsArray, validate, ValidationError } from "class-validator";
import { AppFeeback } from "../enums/app-feedback.enum";
import { AppUtilitie } from "../utilities/app-utilitie";



@Injectable({
    providedIn: 'root',
})

export class AppConfigService{

    userErrors:any={}
    
    private dialogRef: MatDialogRef<LoadingComponent> | undefined;
    public  waiting$: Subject<boolean> = new Subject<boolean>();
    public sendAction$:Subject<string>=new Subject<string>()

    constructor(
        private dialog: MatDialog,
    ){}

    onConnected(login: any): boolean{
        console.log('here ', login)
        if(!login)
            return false;
        else{
            localStorage.setItem('applications', JSON.stringify(login.applications));
            localStorage.setItem('user', JSON.stringify(login.user));
            localStorage.setItem('token', login.token);
            localStorage.setItem('userAuthorisation', JSON.stringify(login.userAuthorisation));
             window.location.href='/home' 
            return true;
        }
    }

    onDisconnect(){
        localStorage.clear();
    }

    sendAction(label:string){
      this.sendAction$.next(label)
    }

    async validateOrReject(data:any) {
        try {
      
          await validate(data).then((errors: any) => {
            if (Array.isArray(errors) && errors.length > 0){
              console.log('missed ', errors)
              throw errors;
            }
            else{
                console.log("ok")
                const result:any=true
               return result;
            }
             
          });
      
        } catch (errors:any) {
      
          if(Array.isArray(errors)){
            this.userErrors = {}
            errors.map((error: ValidationError) => {
              this.userErrors[error.property] = error.constraints?.['isNotEmpty'] || error.constraints?.['isEmail']
            })
            return this.userErrors
          }else{
            AppUtilitie.openInfoDialog(this.dialog, AppFeeback.NETWORK_ERROR)
          }
        }
      }

onLazyLoad(){
    this.waiting$.next(true);
}
onStopLazyLoad(){
    this.waiting$.next(false);
}

    onStartWaiting(message?: string){

        if(this.dialogRef){
            this.dialogRef.close()
        }
        this.dialogRef = this.dialog.open(LoadingComponent, {
            disableClose: true,
            data: {
                message: message
            }
        })
    }

    onStopWaiting(){
        if (this.dialogRef){
            this.waiting$.next(false);
            this.dialogRef.close()
        }
    }

    getItemFromlocalStorage(item: string){
        const response = localStorage.getItem(item);
        if(response)
            return response;

        return null;
    }


    controlAccessModule(userModules: string[]): boolean{

        if(!userModules || userModules.length <= 0)
            return false;

        let session: string | null = this.getItemFromlocalStorage("modules");
        let modules: any[] = new Array();

        if(!session)
            return false

        modules = JSON.parse(session);

        if (!modules || modules.length <= 0)
            return false;

        for (const moduleAccess of modules) {
            for (const chr of userModules) {

                if (moduleAccess.moduleName != chr)
                    continue;

                else return true;
            }
        }

        return false;
    }


        controlAccessModuleAndPrivilege(application:string,module: string, privilege?: string| undefined){

        let canView:boolean=false; 
        let userAuthorization:any= localStorage.getItem("userAuthorisation");
        userAuthorization=JSON.parse(userAuthorization)


        userAuthorization=userAuthorization.find((auth:any)=>auth.application.application==application)
    
       if(userAuthorization!=undefined){
        for(let auth of userAuthorization.autorisation){
    
            const hasPrivilege=auth.role.privileges.some((value:any)=>value.privilege==privilege)
      
            if(auth.module.module==module && (hasPrivilege || privilege==undefined)){
                canView=true
                break; 
            }
            else{
              canView=false
            }
      
          };
       }


        return canView
      
      }


    public convertToClientTimeZone(date:string) {

        const formatdateCommand=date.replace(/([0-9]{2})\/([0-9]{2})\/([0-9]{2})/,'$2/$1/$3')+" +0000"
        const clientTZ=Intl.DateTimeFormat().resolvedOptions().timeZone
        return new Date(formatdateCommand).toLocaleString("fr-FR", {timeZone: clientTZ}); 
        
    }


    getItemFromSessionStorage(item: string){
        const response = localStorage.getItem(item);
        if(response)
            return response;

        return null;
    }

    controlAccessModuleAndPrivilege2(module: string, privilege: string){
        if(!module || module.length <= 0)
            return false

        if(!privilege || privilege.length <= 0)
            return false

        let session: string | null = this.getItemFromlocalStorage("modules");
        let modules: any[] = new Array();

        if(!session)
            return false

        modules = JSON.parse(session);

        for (const moduleAccess of modules) {
            if (module == moduleAccess.moduleName && moduleAccess.privileges.includes(privilege))
                return true;
        }

        return false;
    }


    controlAccessApplication(){
        let appModuleRoleResponses:any= this.getItemFromSessionStorage('appModuleRoleResponses');
        appModuleRoleResponses=JSON.parse(appModuleRoleResponses);

        appModuleRoleResponses.forEach((value:any) => {
            if(value.application.url=='host'){
                sessionStorage.setItem('modules', JSON.stringify(value.moduleRoleResponses));
                return true
            }
            return false
        });
    }
}
