<form class="container-fluid bg-white p-4 rounded shadow" data-aos="fade-right" style="max-width: 500px;">
    <!-- Bouton de fermeture en haut à droite -->
    <div class="d-flex justify-content-end m-0" mat-dialog-close>
        <fa-icon [icon]="faTime" style="cursor: pointer; font-size: 1.5rem; color: #888;"></fa-icon>
    </div>

    <!-- Titre du formulaire -->
    <h4 class="text-center mb-4" style="font-weight: bold; color: #333;">Votre QR Code</h4>

    <!-- Message d'invitation et étapes -->
    <div class="text-center">
        <p style="color: #555;">Pour sécuriser votre compte, suivez ces étapes et scannez le QR Code avec Microsoft Authenticator :</p>
        <ol class="text-start" style="color: #555; font-size: 0.9rem;">
            <li>Ouvrez l'application <strong>Microsoft Authenticator</strong> sur votre téléphone.</li>
            <li>Sélectionnez <strong>Ajouter un compte</strong> puis <strong>Compte professionnel ou scolaire</strong>.</li>
            <li>Scannez le QR Code ci-dessous pour lier votre compte.</li>
        </ol>
    </div>

    <!-- QR Code -->
    <div class="d-flex justify-content-center mt-3">
        <div *ngIf="qrCode" class="text-center">
            <qrcode [qrdata]="qrCode" [width]="250" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
        </div>
    </div>
</form>
