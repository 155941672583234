import { validate, ValidationError } from "class-validator";

export class ValidatorUtilitie {

    static controValue(value: any, msg: string){
  
      if(!value)
        throw msg;
  
      if(typeof value == 'string' && value.length <= 0)
        throw msg;
  
      if(typeof value == 'number' && value <= 0)
      throw msg;
    }
  
    private static fillObject(obj:any, error: ValidationError){
        if(!error.constraints && error.children!!.length > 0){
          for(const child of error.children!!)
           this.fillObject(obj, child)
        }else{
          for(const constraint of Object.values(error.constraints!!)){
            if(!obj[error.property])
              obj[error.property] = ''
  
            if(obj[error.property].length == 0)
              obj[error.property] += ''+constraint
            else
            obj[error.property] += '\n'+constraint
          }
        }
    }
  
    static async controlEntity(data: any): Promise<any>{
      return new Promise<any>((resolve, reject) => {
        validate(data).then(
          error => {
             if(Array.isArray(error) && error.length > 0){
  
               let object = error.map(e => {
                  let datum:any = {}
  
                  this.fillObject(datum, e)
  
                  return datum;
               })
  
               let obj = object.reduce<any>((previous, current) => {
  
                 for(const v of Object.keys(current))
                  previous[v] = current[v]
  
                return previous
               }, {})
  
               reject(obj)
             }
  
             resolve("Entité OK")
          }
        )
      })
    }
  
}