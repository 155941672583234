import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faCheck, faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { validate, ValidationError } from 'class-validator';
import { UpdateEntity } from 'src/app/entities/updateEntity';
import { AppFeeback } from 'src/app/enums/app-feedback.enum';
import { AppConfigService } from 'src/app/services/app-config.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/users.service';
import { AppUtilitie } from 'src/app/utilities/app-utilitie';
import { AuthQrcodeComponent } from '../auth-qrcode/auth-qrcode.component';

@Component({
  selector: 'app-mot-de-passe',
  templateUrl: './mot-de-passe.component.html',
  styleUrls: ['./mot-de-passe.component.scss']
})
export class MotDePasseComponent implements OnInit {

  faTime=faTimes
  faCheck=faCheck
  user!:any;

  confirmPassword: string = "";
  message: string = "";
  userErrors: any = {};
  data:UpdateEntity=new UpdateEntity();
  constructor(
    private dialog: MatDialog,
    private dialogRef:MatDialogRef<MotDePasseComponent>,
    private userService: UserService,
    private appConfig: AppConfigService,
    private authService: AuthenticationService,
    private route:Router
  ) { }

  ngOnInit(): void {
    let user:any=this.appConfig.getItemFromlocalStorage("user")
    this.user=JSON.parse(user)

    console.log('user objet ', this.user)
  }
  clear(){
    this.data=new UpdateEntity();
  }
  isValid !: { valid: boolean; message: string };
  isStrong !: { valid: boolean; message: string };

  confirm!:string;

  validPassword(){
    this.isValid=AppUtilitie.isStrongPassword(this.data.newPassword);
    this.isStrong=AppUtilitie.isStrongPassword(this.data.newPassword);
  }

  async validateOrReject() {
    this.data.username=this.user.username
    console.log('data.username', this.user.username )
    try {

      await validate(this.data).then((errors: any) => {
        if (Array.isArray(errors) && errors.length > 0) 
          throw errors

        else if (!this.isValid.valid){
          AppUtilitie.openInfoDialog(this.dialog, "Entrez au moins une lettre majuscule, une lettre minuscule, un chiffre et le caractère spécial suivant : &@_#*")
          throw errors
        }
          
        else{
          this.update()
        }
       
      });

    } catch (errors) {

      if(Array.isArray(errors)){
        this.userErrors = {}
        errors.map((error: ValidationError) => {
          this.userErrors[error.property] = error.constraints?.['isNotEmpty']
        })
      }
      
      else{
        console.log('error', errors)
        AppUtilitie.openInfoDialog(this.dialog, "Entrez au moins une lettre majuscule, une lettre minuscule, un chiffre et le caractère spécial suivant : &@_#*")
      }
    }
  }

  isSamePassword(){



    if(this.data.newPassword===this.confirm){
      this.validateOrReject()
      //this.update()
    }
    else{
      AppUtilitie.openInfoDialog(this.dialog, "Vous devez confirmer le même nouveau mot de passe, veuillez réessayer. !")
    }
   
  }

  reset(string:string){
    if(string=='newPassword'){
      this.userErrors.newPassword=" ";
    }
    else{
      this.userErrors.oldPassword=" ";
    }
  
  }

  update(){

    this.data.newPassword=this.data.newPassword.trim()
    this.data.oldPassword=this.data.oldPassword.trim()
  
    console.log('user ',  this.data.username)

    this.appConfig.onStartWaiting()
    this.authService.updatePassword(this.data)
    .subscribe(
      {
        next:(res)=>{
          this.appConfig.onStopWaiting();
          AppUtilitie.openInfoDialog(this.dialog,AppFeeback.SAVE_SUCCESS,faCheckCircle)
          this.showQrcode()
        },
        error:(err)=>{
          this.appConfig.onStopWaiting();
          AppUtilitie.openInfoDialog(this.dialog,err)

        }
      }
    )

  }


  showQrcode(){
    this.dialog.open(AuthQrcodeComponent, {
      id:'transparent',
      disableClose:true,
      width:"30%",
      height:"80%"
    }).afterClosed().subscribe(res=>{
      this.dialogRef.close()

      this.route.navigate(['/'],{replaceUrl:true});
      this.clear();

    })
  }

}
