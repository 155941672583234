<section class="row p-3 mt-3 shadow-custom bg-white" style="border-radius: 17px;"
data-aos="fade-right"
data-aos-offset="300"
>
    <form >
        <div class="row">

            <div class="col-md-4 my-2">
              <label class="label-form mb-2 ">Module<sup class="text-danger">*</sup></label>
                <input type="text" name="roleName" [(ngModel)]="module.module" class="form-control" id="role" autocomplete="off" placeholder="">
                <p class="my-2 message-error">
                  {{ userErrors?.module ? userErrors.module: "" }}
                </p>
            </div>

            <div class="col-md-7 my-2">
              <label class="label-form mb-2">Application</label>

              <select class="form-select" name="application" [(ngModel)]="module.applicationId" >
                <option> Sélectionner l'application </option>
              </select>
                <p class="my-2 message-error">
                    {{ userErrors?.applicationId ? userErrors.applicationId: "" }}
                </p>
            </div>

        </div>

        <div class="mt-4">
            <button type="submit" class="btn btn-success-custom  me-3 px-4 rounded-2"  (click)="validateBeforeSendData()">
                Enregistrer
            </button>
            <button type="reset" class="btn btn-secondary px-4 rounded-2 ">
                Réunitialiser
            </button>
        </div>

    </form>

</section>

