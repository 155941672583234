import { Component, OnInit } from '@angular/core';
import { Application } from 'src/app/entities/application';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-add-application',
  templateUrl: './add-application.component.html',
  styleUrls: ['./add-application.component.scss']
})
export class AddApplicationComponent implements OnInit {

  user:any;
  fichier:any;
  application:Application=new Application()
  userErrors: any = {};

  constructor(
    public appConfig: AppConfigService,
    private applicationService: ApplicationService,
  ) { }

  ngOnInit(): void {
    this.user = this.appConfig.getItemFromlocalStorage("user");
    this.user = JSON.parse(this.user);
  }

  

  async save() {
    this.applicationService.save(this.application, `/app/settings/menu/systeme/list-application`);
  }

  async validateBeforeSendData() {
    const canSave = await this.appConfig.validateOrReject(this.application);

    if (canSave == undefined || canSave == true) {
      await this.save() ;
    } else {
      this.userErrors = await this.appConfig.validateOrReject(this.application);
    }
  }

}
