export enum AppFeeback {
    SAVE_SUCCESS = "L'opération a été effectuée avec succès !",
    DELETE_SUCCESS="La suppression a été effectuée avec success",
    UPDATE_SUCCESS="La modification a été effectuée avec success",
    
    SESSION_EXPIRED = "Votre session a expiré",
    EXTERNAL_ERROR = "Une erreur s'est produite, veuillez contacter le support technique",
    NETWORK_ERROR = "Une erreur s'est produite, veuillez contacter le support technique",
    BAD_CREDENTIAL_ERROR = "Les informations saisies ne sont pas correctes, vous devez renseignez les bonnes informations  ou  contacter le support technique",
    YES = "YES",
    NOT_FOUND = "Contenu non trouvé",
    NOT_FOUND_ERROR = "Aucun contenu n'a été trouvé"
}


