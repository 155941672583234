import { Component, OnInit } from '@angular/core';
import { ModuleApplication } from 'src/app/entities/module';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-add-modules',
  templateUrl: './add-modules.component.html',
  styleUrls: ['./add-modules.component.scss']
})
export class AddModulesComponent implements OnInit {

  user:any;
  fichier:any;
  module:ModuleApplication=new ModuleApplication()
  userErrors: any = {};

  constructor(
    public appConfig: AppConfigService,
  ) { }

  ngOnInit(): void {
  }


  async validateBeforeSendData() {
    const canSave = await this.appConfig.validateOrReject(this.module);

    if (canSave == undefined || canSave == true) {
      //await this.sendFilesFromCloudinary();
    } else {
      this.userErrors = await this.appConfig.validateOrReject(this.module);
    }
  }

}
