import { IsString, IsNotEmpty, IsNumber } from "class-validator";

export class ModuleApplication{
    @IsString()
    @IsNotEmpty()
    module!: string;

    @IsNumber()
    @IsNotEmpty()
    applicationId!:number
}