<div class="d-flex justify-content-end mb-2">
    <fa-icon [icon]="faClose" mat-dialog-close></fa-icon>
</div>
<div class="marge-bottom">
<img src="/assets/logo.png" class="img-fluid rounded-circle " width="200">
<h3 class="text-center border-bottom border-danger p-2 bg-light mb-4">Informations sur l'utilisateur</h3>

<div class="row">
    <div class="col-12 mt-3">
        <div class="row">

            <div class="col-12 col-lg-7 ms-3 " >
                <dl class="row">
                    <dt class="col-4">Numero pièce d'identité </dt>
                    <dd class="col-8">: </dd>
                    <dt class="col-4">Nom d'utilisateur </dt>
                    <dd class="col-8">: {{userInfo?.username}} </dd>
                    <dt class="col-4">Prenom </dt>
                    <dd class="col-8">: {{userInfo.agent?.firstName}}</dd>
                    <dt class="col-4">Nom </dt>
                    <dd class="col-8">: {{userInfo.agent?.name}}</dd>
                    <dt class="col-4">Postnom </dt>
                    <dd class="col-8">: {{userInfo.agent?.middleName}} </dd>
                    <dt class="col-4">Sexe </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo.sex}}</dd>
                    <dt class="col-4">Situation matrimoniale </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.etatCivil}}</dd>
                    <dt class="col-4">Lieu de Naissance </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.cityOfBirth}}</dd>
                    <dt class="col-4">Date de Naissance </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.dateOfBirth}}</dd>
                    <dt class="col-4">Nationalité </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.nationality}}</dd>
                    <dt class="col-4">Numero de téléphone </dt>
                    <dd class="col-8">: {{ (userInfo.agent.personalInfo?.phonePrefix) ? userInfo.agent?.personalInfo?.phonePrefix+' '+userInfo.agent?.personalInfo?.phoneNumber : '-'}}</dd>
                    <dt class="col-4">Email professionnel </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.workEmail}}</dd>
                    <dt class="col-4">Email privé </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.email}}</dd>
                    <dt class="col-4">Pays </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.address?.commune?.city?.province?.country?.name}}</dd>
                    <dt class="col-4">Province</dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.address?.commune?.city?.province?.name}}</dd>
                    <dt class="col-4">Cité </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.address?.commune?.city?.name}}</dd>
                    <dt class="col-4">Commune </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.address?.communeStr }}</dd>
                    <dt class="col-4">Avenue </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.address?.street}}</dd>
                    <dt class="col-4">Matricule </dt>
                    <dd class="col-8">:{{userInfo.agent?.personalInfo?.idnumber}} </dd>
                    <dt class="col-4">Numero de badge </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.badgeNumber}}</dd>
                      <dt class="col-4">Type de contrat </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.typeContrat}}</dd>
                    <dt class="col-4">Bureau </dt>
                    <dd class="col-8">: {{userInfo.agent?.office?.name}}</dd>
                    <dt class="col-4">Region </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.region}}</dd>
                    <dt class="col-4">Fonction </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.fonction}}</dd>
                    <dt class="col-4">Superieure hierarchique </dt>
                    <dd class="col-8">: </dd>
                      <dt class="col-4">Affectation </dt>
                    <dd class="col-8">: </dd>
                    <dt class="col-4">Departement </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.department}}</dd>

                    <dt class="col-4">Personne de contact </dt>
                    <dd class="col-8">: {{userInfo.agent?.personalInfo?.personToContact}}</dd>

                    <dt class="col-4 mt-1">Statut </dt>
                    <dd class="col-8 mt-1">: {{userInfo.status}}</dd>
                    <dt class="col-4">Date d'enregistrement </dt>
                    <dd class="col-8">: {{userInfo.agent.dateSaved}}</dd>

                </dl>
                 <button  class="btn btn-secondary rounded-pill " *ngIf=" appConfig.controlAccessModuleAndPrivilege('USER ACCESS MANAGEMENT', 'MODIFIER') " style="font-size:16px;" (click)="confirmReset(userInfo.agent.id, userInfo.username)">
                    <fa-icon [icon]="faRefresh"></fa-icon>  Réinitialiser le mot de passe
                 </button>
                 <button class="btn btn-warning text-white rounded-pill mx-3" *ngIf=" appConfig.controlAccessModuleAndPrivilege('USER ACCESS MANAGEMENT', 'MODIFIER') " style="font-size:16px;" (click)="redirectUpdateUser(userInfo)" >
                   <fa-icon [icon]="faEdit"></fa-icon>    Modifier
                </button>
                <button class="btn btn-success-custom rounded-pill " *ngIf=" appConfig.controlAccessModuleAndPrivilege('USER ACCESS MANAGEMENT', 'SUPPRIMER') " style="font-size:16px;" (click)=" confirmDelete(userInfo.agent.id)">
                   <fa-icon [icon]="faClose"></fa-icon> Supprimer l'utilisateur
                </button>
            </div>
            <div class="col-4 ms-5 ">
                <div class="border " style="width:250px; height:300px;">
                    <img [src]="profileImg" class="img-fluid rounded-3" alt="Photo de profil de l'utilisateur" style="width:250px; height:300px !important; background-size: cover; "  >
                </div>
            </div>
        </div>
    </div>
</div>
</div>
