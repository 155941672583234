import { IsNotEmpty, IsArray, ArrayMinSize } from "class-validator";
import { RoleModuleForms } from "./role-module-form";

export class UserAccessModule{
    
    @IsNotEmpty({ message: "Utilisateur obligatoire" })
    userId!: number;

    @IsNotEmpty({ message: "Utilisateur obligatoire" })
    applicationId!: number;

    @IsArray({ message: "Veuillez renseigner les accès" })
    @ArrayMinSize(1, { message: "Veuillez renseigner les accès" })
    accessUser!: RoleModuleForms[]
}