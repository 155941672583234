import { IsString, IsOptional, IsBoolean } from "class-validator";

export class UserSignDto {

    @IsString()
    signature!:string;

    @IsOptional()
    @IsBoolean()
    isSetPassword:boolean=true
}