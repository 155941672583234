import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

import { Repository } from "../repository/repository";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { AppFeeback } from "../enums/app-feedback.enum";
import { AppUtilitie } from "../utilities/app-utilitie";
import { AppConfigService } from "./app-config.service";
import { Subject } from "rxjs";


@Injectable({
    providedIn: 'root',
})
export class UserService extends Repository< any >{

    users$:Subject<any>=new Subject<any>()
    signature$:Subject<any>=new Subject<any>()
    directeur$:Subject<any>=new Subject<any>()

    constructor(
        protected override _http: HttpClient, 
        protected override _router: Router,
        protected override _dialog: MatDialog,
        private appConfig: AppConfigService,
    ) {
        super(_http, `${environment.baseUrl}/user`, _router, _dialog);
    }

    save(data:any, redirectTo:string, uri:any=""){
        this.appConfig.onStartWaiting()
    
        this.save$(data,uri).subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, AppFeeback.SAVE_SUCCESS, faCheckCircle)
          
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })

        if(redirectTo.includes('app')){
          console.log('voir', redirectTo)
          this._router.navigate([redirectTo], {replaceUrl:true})
        }
    
      }
    
      get(uri:string=""){
        this.appConfig.onStartWaiting()
        
        this.find$(uri).subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()

            if(uri.includes('signature')) this.signature$.next(res)
            else
              this.users$.next(res)
           
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })
      }


      resetPassword(uri:string=""){
        this.appConfig.onStartWaiting()
        
        this.find$(uri).subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()

          
           
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })
      }

      getDirecteur(uri:string=""){
        this.appConfig.onStartWaiting()
        
        this.find$(uri).subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()
            this.directeur$.next(res.data)
           
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            this.directeur$.next(false)
            console.log('error', error)
            //AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })
      }

      deleteItem(id:number){
        this.delete$(`${id}`).subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, AppFeeback.DELETE_SUCCESS, faCheckCircle)
            this.get()
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })
      }
    
      update(data:any ,uri:any, redirectTo?:string){
        this.appConfig.onStartWaiting()
        this.patch$(data,uri).subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, AppFeeback.UPDATE_SUCCESS, faCheckCircle)

            if(redirectTo) this._router.navigate([redirectTo], {replaceUrl:true})
            this.get()
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })
      }
}