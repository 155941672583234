<div class="d-flex justify-content-end mb-2">
    <fa-icon [icon]="faClose" mat-dialog-close></fa-icon>
  </div>
  <section >
    <form >
        <h3>Délegation du privilège de validation</h3>
        <div class="row">
  
          <div class="col-md-6 my-2">
  
          
            <label class="label-form mb-2">Selectionner l'agent délégateur</label>
                <div class="col-12 py-2">
                  
                    <input  type="text" class="form-select" id="agent2" name="labelInput2"  readonly autocomplete="off" [(ngModel)]="agent2"   (click)="toggleList2()"   required/>
        
                    <ul  *ngIf="isToggleList2"  class="col-3 list-group mt-2 position-absolute list-user-hover bg-white"
                      style="height: 35vh; overflow-y: scroll; z-index: 9999; border:silver 1px solid !important;">
                    
                      <input type="search" class="form-control mx-auto my-3 " [(ngModel)]="search2" (ngModelChange)="filteredAgent2()" style="width:90% !important;" name="searchAgent" required/>
        
                      <li *ngFor="let agent of filteredAgent2()"   class="list-group-item d-flex bg-white jus border-end-0 border-start-0 border-top-0" style="border-radius: none !important;"
                          style="color: #000;">
  
                          <input type="checkbox" class="checkable" (click)="selectedAgent2(agent)"/>
                          <p class="mt-1 mb-0 ms-3 text-start d-flex py-1" style="font-size: 14px !important; " (click)="selectedAgent2(agent)"> <span class="me-2">{{agent.prenom.toUpperCase()}}</span> <span>{{agent.nom.toUpperCase()}}</span> &nbsp;<span> {{agent.postnom.toUpperCase()}}</span> </p>
                      </li>
                    </ul>
  
                </div>
          </div>

          <div class="col-md-6 my-2">
  
          
            <label class="label-form mb-2">Selectionner l'agent à déléguer</label>
                <div class="col-12 py-2">
                  
                    <input  type="text" class="form-select" id="agent" name="labelInput"  readonly autocomplete="off" [(ngModel)]="agent"   (click)="toggleList()"   required/>
        
                    <ul  *ngIf="isToggleList"  class="col-3 list-group mt-2 position-absolute list-user-hover bg-white"
                      style="height: 35vh; overflow-y: scroll; z-index: 9999; border:silver 1px solid !important;">
                    
                      <input type="search" class="form-control mx-auto my-3 " [(ngModel)]="search" (ngModelChange)="filteredAgent()" style="width:90% !important;" name="searchAgent" required/>
        
                      <li *ngFor="let agent of filteredAgent()"   class="list-group-item d-flex bg-white jus border-end-0 border-start-0 border-top-0" style="border-radius: none !important;"
                          style="color: #000;">
  
                          <input type="checkbox" class="checkable" (click)="selectedAgent(agent)"/>
                          <p class="mt-1 mb-0 ms-3 text-start d-flex py-1" style="font-size: 14px !important; " (click)="selectedAgent(agent)"> <span class="me-2">{{agent.prenom.toUpperCase()}}</span> <span>{{agent.nom.toUpperCase()}}</span> &nbsp;<span> {{agent.postnom.toUpperCase()}}</span> </p>
                      </li>
                    </ul>
  
                </div>
          </div>
         
        </div> 
  
        <div class="mt-4">
            <button type="submit" class="btn btn-success-custom  me-3 px-4 " (click)="confirmDelegate()" >
                Continuer
            </button>
        </div>
    </form>
  
  </section>
  