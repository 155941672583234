<section class="row p-3 mt-3 shadow-custom bg-white" style="border-radius: 17px;"
data-aos="fade-right"
data-aos-offset="300"
>
    <form >
        <div class="row">

            <div class="col-md-4 my-2">
              <label class="label-form mb-2 ">Application<sup class="text-danger">*</sup></label>
                <input type="text" name="roleName" [(ngModel)]="application.application" class="form-control" id="role" autocomplete="off" placeholder="">
                <p class="my-2 message-error">
                  {{ userErrors?.application ? userErrors.application: "" }}
                </p>
            </div>

            <div class="col-md-7 my-2">
              <label class="label-form mb-2">Description</label>
                <input type="text" [(ngModel)]="application.description" name="roleDescription" class="form-control" id="pass" placeholder="">
                <p class="my-2 message-error">
                    {{ userErrors?.description ? userErrors.description: "" }}
                  </p>
            </div>

            <div class="col-md-5 my-2">
                <label class="label-form mb-2">Url de redirection</label>
                  <input type="text" [(ngModel)]="application.redirectUrl" name="roleDescription" class="form-control" id="pass" placeholder="">
                  <p class="my-2 message-error">
                    {{ userErrors?.redirectUrl ? userErrors.redirectUrl: "" }}
                  </p>
            </div>

            <div class="col-md-5 my-2">
                <label class="label-form mb-2">Logo</label>
                <input type="file"  name="img" accept=".png, .jpg, .jpeg" (change)="uploadFile($event)" class="form-control" >
            </div>

        </div>

        <div class="mt-4">
            <button type="submit" class="btn btn-success-custom  me-3 px-4 rounded-2"  (click)="validateBeforeSendData()">
                Enregistrer
            </button>
            <button type="reset" class="btn btn-secondary px-4 rounded-2 ">
                Réunitialiser
            </button>
        </div>

    </form>

</section>
