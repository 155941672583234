import { Component, OnInit } from '@angular/core';
import { ConfirmDialogComponent } from '../utilities/confirm-action-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AppConfigService } from 'src/app/services/app-config.service';
import { UserService } from 'src/app/services/users.service';

@Component({
  selector: 'app-delegation',
  templateUrl: './delegation.component.html',
  styleUrls: ['./delegation.component.scss']
})
export class DelegationComponent implements OnInit {

  agent!:any;
  faClose=faTimes;
  users: any[] = new Array();
  agentId!:number;
  message!:string;

  labelInput!:string;
  search!:string;
  isToggleList!:boolean;

  agent2!:any;
 
  users2: any[] = new Array();
  agentId2!:number;
  message2!:string;

  labelInput2!:string;
  search2!:string;
  isToggleList2!:boolean;
  
  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    public appConfig: AppConfigService,
    public router:Router,
    private dialogRef:MatDialogRef<DelegationComponent>
  ) { }

  ngOnInit(): void {
    this.getListAgents()
  }

  getListAgents(){
    this.userService.get();
    this.userService.users$.subscribe((res:any)=>{
      this.users=res.data;

      this.users2=res.data;
      console.log('users data ', res)
    })
  }



  selectedAgent(agent:any){
   
    this.agent=agent.prenom.toUpperCase()+" "+agent.nom.toUpperCase()+" "+agent.postnom.toUpperCase();
    this.agentId=agent.id;
    this.isToggleList=!this.isToggleList
  
  }

  selectedAgent2(agent:any){
   
    this.agent2=agent.prenom.toUpperCase()+" "+agent.nom.toUpperCase()+" "+agent.postnom.toUpperCase();

    this.agentId2=agent.id;
    this.isToggleList2=!this.isToggleList2
  
  }


  toggleList(){
    this.isToggleList=!this.isToggleList
  }



  toggleList2(){
    this.isToggleList2=!this.isToggleList2
  }

  filteredAgent() {

    console.log('item', this.users, this.search)
    if (this.search) {
      return this.users.filter((value:any) => 
        ( value.nom?.toLowerCase().includes(this.search.toLowerCase()) || value.prenom?.toLowerCase().includes(this.search.toLowerCase()) || value.postnom?.toLowerCase().includes(this.search.toLowerCase()) )
      );
    } else {
      return this.users;
    }
  }

  filteredAgent2() {

    console.log('item', this.users2, this.search2)
    if (this.search2) {
      return this.users2.filter((value:any) => 
        ( value.nom?.toLowerCase().includes(this.search.toLowerCase()) || value.prenom?.toLowerCase().includes(this.search.toLowerCase()) || value.postnom?.toLowerCase().includes(this.search.toLowerCase()) )
      );
    } else {
      return this.users2;
    }
  }


  confirmDelegate(){
    this.dialog.open(ConfirmDialogComponent, {
      data:{
        message:`Voulez-vous vraiment laissé l'agent :  ${this.agent} comme interim ?`
      }
    }).afterClosed().subscribe(res => {
      if(res == "YES"){
         this.dialogRef.close({idAgentTodelegate: this.agentId, idAgent: this.agentId2})
      }
    })
  }

  close(){
    this.dialogRef.close()
  }

}
