import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as Aos from 'aos';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AppFeeback } from 'src/app/enums/app-feedback.enum';
import { AppConfigService } from 'src/app/services/app-config.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppUtilitie } from 'src/app/utilities/app-utilitie';

@Component({
  selector: 'app-auth-qrcode',
  templateUrl: './auth-qrcode.component.html',
  styleUrls: ['./auth-qrcode.component.scss']
})
export class AuthQrcodeComponent implements OnInit {

  user2FA!:any;
  user!:any;
  faTime=faTimes;
  qrCode: any;
  constructor(
    private authService: AuthenticationService,
    private appConfig: AppConfigService,
    private dialog: MatDialog,
    private dialogRef:MatDialogRef<AuthQrcodeComponent>,
  ) { }

  ngOnInit(): void {
    let user:any=this.appConfig.getItemFromlocalStorage("user")
    this.user=JSON.parse(user)
    this.onSetUp2FA()
  }

  onSetUp2FA() {
    this.user2FA = { userId: this.user.id };
    this.appConfig.onStartWaiting();
    this.authService.setup2FA(this.user2FA).subscribe({
      next: (res: any) => {
        this.appConfig.onStopWaiting();
        this.qrCode = res.otpAuthUrl;
        AppUtilitie.openInfoDialog(this.dialog, `Veuillez créer un compte Microsoft authentificator en scannant ce Qrcode !`, faCheck, false, false);
      },
      error: () => {
        this.appConfig.onStopWaiting();
        AppUtilitie.openInfoDialog(this.dialog, AppFeeback.BAD_CREDENTIAL_ERROR);
      }
    });
  }

}
