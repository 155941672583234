<form class=" container-fluid bg-white " data-aos="fade-right">

    <div class="d-flex justify-content-end m-0" *ngIf="this.user?.isSetPassword==true" mat-dialog-close>
        <fa-icon [icon]="faTime"></fa-icon>
    </div>

    <h4>Modifier votre mot de passe</h4>

    <div class="row mt-3">
  
          <div class="col-md-12 my-2">
            <label class="label-form mb-2 fw-600">Mot de passe actuel</label>
              <input type="password" name="roleName" [(ngModel)]="data.oldPassword"  class="form-control" id="role" autocomplete="off" >
              <p class="my-2 message-error">
                {{ userErrors?.oldPassword ? userErrors.oldPassword : "" }}
            </p>
            </div>

        <div class="col-md-12 my-2 me-3">
          <label class="label-form mb-2 fw-1200">Nouveau mot de passe</label>

            <input type="password" name="new" required  [(ngModel)]="data.newPassword" (ngModelChange)="validPassword()"  class="form-control" id="role" autocomplete="off"  >
            <p class="my-2 message-error">
              {{ userErrors?.newPassword ? userErrors.newPassword : "" }}
          </p>
          <p class="my-2 message-error" *ngIf="isValid && !isValid.valid" >
           {{isValid.message}}
        </p>
        <p class="my-2 text-success" *ngIf=" isStrong && isStrong.valid==true" >
          {{isValid.message}} et ça assure une bonne sécurité de votre compte <fa-icon [icon]="faCheck"></fa-icon>
        </p>

          </div>
     
        <div class="col-md-12 my-2">
          <label class="label-form mb-2">Confirmer le nouveau mot de passe</label>
            <input type="password" name="confirm" [(ngModel)]="confirm"  required  class="form-control" id="pass" >
          </div>

        <div class="mt-5 " >
            <button  type="submit" [class]="data.oldPassword && data.newPassword && confirm ? 'btn btn-success-custom  me-3 px-4 rounded-2' : 'btn btn-secondary  me-3 px-4 rounded-2' " (click)="data.oldPassword && data.newPassword && confirm ? isSamePassword() : ''">
               Modifier
            </button>

        </div>
    </div>
</form>



