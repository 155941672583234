import { IsNotEmpty } from "class-validator";
import { GenericValidatorMessages } from "../utilities/genertic-validator-message";

export class UpdateEntity{
    @IsNotEmpty(GenericValidatorMessages.genericEmptyMessage("Nouveau mot de passe"))
    newPassword!: string;
    @IsNotEmpty(GenericValidatorMessages.genericEmptyMessage("L'ancient mot de passe"))
    oldPassword!: string

    username?: string
}