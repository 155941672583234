import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faCheck, faFilter, faCheckSquare, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { RoleModuleForms } from 'src/app/entities/role-module-form';
import { UserAccessModule } from 'src/app/entities/userAccessModule';
import { ModuleInterface } from 'src/app/interfaces/users/modules/module.interface';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ApplicationService } from 'src/app/services/application.service';
import { RoleService } from 'src/app/services/role.service';
import { UserService } from 'src/app/services/users.service';
import { ValidatorUtilitie } from 'src/app/utilities/validator.utilitie';

@Component({
  selector: 'app-granting',
  templateUrl: './granting.component.html',
  styleUrls: ['./granting.component.scss']
})
export class GrantingComponent implements OnInit {

    // Importation Icons FontAwesome
    faCheck = faCheck;
    faFilter = faFilter;
    faCheckSquare = faCheckSquare;
    faCaretLeft=faCaretLeft
  
    // Boolean Variables
    isUpdate: boolean = false;

    // String Variables
    message: string = "";
    motclef: string = "";
  
    // Number Variables
    idapplication: number = 0;
    idApplicationActive!: number;
    userAccessModule: UserAccessModule = new UserAccessModule();
    userAccessModuleSelect: Array<any> = new Array();
    modules: Array<any> = new Array();
    roles!: any[];

    users: any[] = new Array();
    applicationList: Array<any> = new Array();
    errors: any;
    user: any;
    
  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private applicationService: ApplicationService,
    public appConfig: AppConfigService,
    private roleService: RoleService,
  ) { }

  ngOnInit(): void {
    this.userAccessModule.accessUser=[]
    this.getListAgents()
  }

  getListAgents(){
    this.userService.get();
    this.userService.users$.subscribe((res:any)=>{
      this.users=res.data;
      console.log('users data ', this.users)
    })

  }

  getApplicationLists(){
    this.applicationService.get();
    this.applicationService.applications$.subscribe((res:any)=>{
      this.applicationList=res.data;
      console.log(' applications ', this.applicationList)
    })
  }

  getRoles(){
    this.roleService.get();
    this.roleService.roles$.subscribe((res:any)=>{
      this.roles=res.data;
      console.log('roles ', this.roles)
    })

    setTimeout(() => {
      this.checkedInputIfUserAccessTrue()
    }, 2000);
  }



  getModuleByIdApplication(id:number){

    this.userAccessModule.accessUser = new Array();
    this.isUpdate=false
    
    this.idapplication = Number(id);
    this.idApplicationActive = this.idapplication;

    const uri=`modules/${id}`

    this.applicationService.getModules(uri);
    this.applicationService.applicationModules$.subscribe((res:any)=>{
    this.modules=res.data.modules;
  
    })
    this.userAccessModule.applicationId= this.idapplication
    this.getUserAccessModules()
  
    console.log(' module application ', this.modules)
    this.getRoles()
  }



  filterUser(){
    if (!this.motclef)
      return this.users;

    return this.users.filter((user: any) => user?.username?.toLowerCase().includes(this.motclef.toLowerCase()) )
  }

  selectUser(user: any){
    this.motclef = "";
    this.userAccessModule.userId = user.id;
    this.user = user
    this.getApplicationLists()
  
  }

  addAccess(role: any, module: any, event: any){
 
    const access: any = {
      moduleId: module.id,
      roleId: role.id,
      enabled: event.target.checked == true ? true : false 
    }

    for (const role of this.roles) {
      // Recuperation de l'input à partir de son Id
      const id = `${access.moduleId} ${role.id}`;
      const input: any = document.getElementById(id);

      if(input.checked == true && access.roleId != role.id)
        input.checked = false; // Dechecker les autres checkbox lorsqu'on clique sur un autre

    }

    // Si le module selectionnez était déjà attribué à un autre rôle, il faut le retirer avant d'ajouter le nouveau 
    for (const roleAccess of this.userAccessModule.accessUser) {
      if (roleAccess.moduleId == access.moduleId) {
        this.userAccessModule.accessUser = this.userAccessModule.accessUser.filter((roleItem:any) => roleItem.moduleId != access.moduleId)
      }
    }

    this.userAccessModule.accessUser.push(access)
    this.userAccessModule.accessUser=this.userAccessModule.accessUser.filter((value:any)=>value.enabled==true)

    

  }

  validation(){
    ValidatorUtilitie.controlEntity(this.userAccessModule)
    .then(res => {
      this.errors = {};
      this.switchRequest()
    }).catch(error => {
      this.errors = error;
    })
  }

  /* Cette méthode fait switch entre 2 methode(modification ou enregistrement)
  Comment 20/06/2022 */
  switchRequest(){
    if (this.isUpdate == true) {
      this.update()
    }else{
      this.save()
    }
  }
  
  // 
  save(){
    this.userService.save(this.userAccessModule,"" ,"/user-access")
    console.log('user ', this.userAccessModule)
  }

  // Envoi d'une requete PUT pour modifier les access d'un utilisateur
  // Comment
  update(){
    this.userService.update(this.userAccessModule,"update-users-access","/app/settings/menu/users/granted")
    console.log('user is updating', this.userAccessModule)
  }

  clear(){
    this.isUpdate = false;
    this.idapplication = 0;
    this.idApplicationActive = 0;
    this.userAccessModule = new UserAccessModule();
    this.userAccessModule.accessUser = new Array();
    this.userAccessModuleSelect = new Array();
  }

  getUserAccessModules(){ 
    this.appConfig.onStartWaiting()
    this.userService.find$(`granted/${this.userAccessModule.userId}/${this.userAccessModule.applicationId}`)
    .subscribe(
      {
        next: (res: any) => {
          this.appConfig.onStopWaiting()
          this.userAccessModuleSelect = res.data;
        },
        error: (e) => {
          this.appConfig.onStopWaiting()
        }
      }
    )
  }

  checkedInputIfUserAccessTrue(){

    const isArrayAndExist: boolean = Array.isArray(this.userAccessModuleSelect) && this.userAccessModuleSelect.length > 0;
 
    console.log('user access ',  isArrayAndExist)

    if (isArrayAndExist) {
      this.isUpdate = true;
      for (const userAccess of this.userAccessModuleSelect) {
        // Recuperation de l'input à partir de son Id
        const id = `${userAccess.moduleId} ${userAccess.roleId}`;
        const input: any = document.getElementById(id);

        // Check l'input si le rôle et module ont déjà été attribué avant
        if(input != null)
          input.checked = true; 
        
        const access: any = {
           moduleId: userAccess.moduleId,
          roleId: userAccess.roleId,
          enabled: true
        }
  
        this.userAccessModule.accessUser.push(access) // 
        console.log('user access ',  this.userAccessModule.accessUser)
      }
    }
  }

  ngOndestroy(){
    this.clear()
  }

}
