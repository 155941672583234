import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { faCheckCircle, faEdit, faRefresh, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import * as Aos from 'aos';
import { AppConfigService } from 'src/app/services/app-config.service';
import { UserService } from 'src/app/services/users.service';
import { AppUtilitie } from 'src/app/utilities/app-utilitie';
import { ConfirmDialogComponent } from '../../dialogs/utilities/confirm-action-dialog/confirm-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-lists',
  templateUrl: './user-lists.component.html',
  styleUrls: ['./user-lists.component.scss']
})
export class UserListsComponent implements OnInit {

  profileImg:any;

  faClose=faTimes;
  faUser=faUser;
  faEdit=faEdit;
  faRefresh=faRefresh

  userInfo:any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data:any,
    private dialog: MatDialog,
    private dialogRef:MatDialogRef<UserListsComponent>,
    private userService: UserService,
    public appConfig: AppConfigService,
    private router:Router,
  ) {
    if(data)
      this.userInfo=data.item
    console.log('user agent passed ', this.userInfo)
  }


  ngOnInit(): void {
    this.getProfile()
    window.scroll(0,0);
    Aos.init()
  }

  confirmReset(idUser:number, name:string){
    this.dialog.open(ConfirmDialogComponent, {
      width: "50%",
      data:{
        message:"Voulez-vous vraiment réinitialiser ce mot de passe ?"
      }
    }).afterClosed().subscribe(res => {
      if(res == "YES"){
        this.resetPassword(idUser,name)
      }
    })
  }

 confirmDelete(idUser:number){
  this.dialog.open(ConfirmDialogComponent, {
    width: "50%",
    data:{
      message:"Voulez-vous vraiment supprimé cet utilisateur ?"
    }
  }).afterClosed().subscribe(res => {
    if(res == "YES"){
      this.deleteUser(idUser)
    }
  })
 }

 sendAction(){
  this.appConfig.sendAction('update')
}


redirectUpdateUser(agent:any){
    this.dialogRef.close()
    this.sendAction()
    this.router.navigate(['/app/settings/menu/users/add-user'],{replaceUrl:true,  queryParams:{ label:'update', agent:JSON.stringify(agent)}})
 }


 getProfile(){
  this.appConfig.onStartWaiting()
  this.userService.find$(`profile/${this.userInfo.id}`)
  .subscribe(
    {
      next: (res: any) => {
        this.appConfig.onStopWaiting()
        //this.router.navigate(['/app/settings/menu/users/list-users'], { replaceUrl: true }) // Redirection vers la liste des utilisateurs
        console.log('file profile', res)
        this.profileImg=res.endPointURL;


      },
      error: (err) => {
        this.appConfig.onStopWaiting()
        AppUtilitie.openInfoDialog(this.dialog, err)
      }
    }
  )
}


  resetPassword(idUser:number, name:string){
    this.appConfig.onStartWaiting()
    this.userService.find$(`resetPassword/${idUser}`)
    .subscribe(
      {
        next: (res: any) => {
          this.appConfig.onStopWaiting()

          AppUtilitie.openInfoDialog(this.dialog, res , faCheckCircle, true)
        },
        error: (e) => {
          console.log(e);
          this.appConfig.onStopWaiting()
          AppUtilitie.openInfoDialog(this.dialog, e)
        }
      }
    )
  }

  deleteUser(idUser:number){
    this.appConfig.onStartWaiting()
    this.userService.delete$(`${idUser}`)
    .subscribe(
      {
        next: (res: any) => {
          this.appConfig.onStopWaiting()

          AppUtilitie.openInfoDialog(this.dialog, 'Utilisateur supprimé avec succès !' , faCheckCircle)
          this.dialogRef.close('deleted');

        },
        error: (e) => {
          console.log(e);
          this.appConfig.onStopWaiting()
          AppUtilitie.openInfoDialog(this.dialog, e)
        }
      }
    )
  }

}
