
export const environment = {
  
  production: false,
  // baseUrl:'http://localhost:5006',

  baseUrl:'https://core.fonasite.app:5508',
  
};

//baseUrl:'http://91.108.120.188, baseUrl:'http://10.140.0.106:8002',
//baseUrl:'http://localhost:5002'   baseUrl:'http://localhost:5006',,

