import { Injectable, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AppUtilitie } from "../utilities/app-utilitie";
import { AppConfigService } from "./app-config.service";


import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { AppFeeback } from "../enums/app-feedback.enum";
import { Repository } from "../repository/repository";

@Injectable({
    providedIn: 'root',
})
export class ApplicationService extends Repository< any >{

    applications$:Subject<any>=new Subject<any>()
    applicationModules$:Subject<any>=new Subject<any>()

    constructor(
        protected override _http: HttpClient, 
        protected override _router: Router,
        protected override _dialog: MatDialog,
        private appConfig: AppConfigService,
    ) {
        super(_http, `${environment.baseUrl}/application`, _router, _dialog);
    }

    save(data:any, redirectTo:string){
        this.appConfig.onStartWaiting()
    
        this.save$(data,"").subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, AppFeeback.SAVE_SUCCESS, faCheckCircle)
            this._router.navigate([redirectTo], {replaceUrl:true})
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })
    
      }
    
      get(uri:any=""){
        this.appConfig.onStartWaiting()
        
        this.find$(uri).subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()
            this.applications$.next(res)
           
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })
    
      }

      getModules(uri:any){
        this.appConfig.onStartWaiting()
        
        this.find$(uri).subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()
            this.applicationModules$.next(res)
           
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })
    
      }
    
      deleteItem(id:number){
        this.delete$(`${id}`).subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, AppFeeback.DELETE_SUCCESS, faCheckCircle)
            this.get()
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })
      }
    
      update(data:any ,uri:number, redirectTo:string){
        this.appConfig.onStartWaiting()
        this.patch$(data,uri).subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, AppFeeback.UPDATE_SUCCESS, faCheckCircle)
            this._router.navigate([redirectTo], {replaceUrl:true})
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })
      }
}