import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-fonctions',
  templateUrl: './add-fonctions.component.html',
  styleUrls: ['./add-fonctions.component.scss']
})
export class AddFonctionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
