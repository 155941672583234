import { Component, OnInit } from '@angular/core';
import { Application } from 'src/app/entities/application';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-add-application',
  templateUrl: './add-application.component.html',
  styleUrls: ['./add-application.component.scss']
})
export class AddApplicationComponent implements OnInit {

  user:any;
  fichier:any;
  application:Application=new Application()
  userErrors: any = {};

  constructor(
    public appConfig: AppConfigService,
    private applicationService: ApplicationService,
  ) { }

  ngOnInit(): void {
    this.user = this.appConfig.getItemFromlocalStorage("user");
    this.user = JSON.parse(this.user);
  }

  async uploadFile(event: any) {
    const files = event.target.files;
    this.fichier = files[0];
    this.application.logo = this.fichier.name;
  }

  async sendUploadedFile(file: any) {
    this.appConfig.onStartWaiting();
    var myHeaders = new Headers();
    myHeaders.append('content-Type', "multipart/form-data");

    var formdata = new FormData();
    formdata.append("file", file, `${this.user.id}-${this.user.prenom}-${this.user.postnom}`);

    var requestOptions: any = {
      method: 'POST',
      body: formdata,
      myHeaders
    };

    try {
      const request = await fetch(`http://10.140.0.106:8002/minio/files/upload`, requestOptions);
      if (request.ok) this.appConfig.onStopWaiting();
      const response = await request.json();
      return response.url;
    } catch (err) {
      this.appConfig.onStopWaiting();
    }
  }

  async sendFilesFromCloudinary() {
    const url = await this.sendUploadedFile(this.fichier);
    await this.save(url);
  }

  async save(logo: any) {
    this.application.logo = logo;
    this.applicationService.save(this.application, `update/signature`);
  
  }

  async validateBeforeSendData() {
    const canSave = await this.appConfig.validateOrReject(this.application);

    if (canSave == undefined || canSave == true) {
      await this.sendFilesFromCloudinary();
    } else {
      this.userErrors = await this.appConfig.validateOrReject(this.application);
    }
  }

}
