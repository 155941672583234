<form class="signature-form" data-aos="fade-up">
  <!-- Close Button -->
  <div class="close-btn-container">
    <button type="button" class="btn-close" mat-dialog-close aria-label="Close"></button>
  </div>

  <!-- Title -->
  <h2 class="form-title">Dessinez votre Signature</h2>

  <!-- Signature and QR Code Section -->
  <div class="content-container">
    <div class="canvas-wrapper">
      <canvas #canvas width="400" height="200" class="signature-canvas"></canvas>
    </div>

    <div class="preview-container">
      <img [src]="downloadedFileUrl" *ngIf="downloadedFileUrl && !qrCode" alt="Image" class="preview-image">
      <div class="qr-code-container" *ngIf="qrCode">
        <qrcode [qrdata]="qrCode" [width]="150" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
      </div>
    </div>
  </div>

  <!-- Action Buttons -->
  <div class="button-container">
    <button class="btn btn-success" (click)="saveSignature()">Enregistrer</button>
    <button class="btn btn-danger" (click)="reset()">Réinitialiser</button>
  </div>
</form>
