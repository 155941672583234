import { CommonModule, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import localeFr from '@angular/common/locales/fr';
import { TokenInterceptorProvider } from './interceptors/token-interceptor';
import { InfoDialogComponent } from './components/dialogs/utilities/info-dialog/info-dialog.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { UserListsComponent } from './components/details/user-lists/user-lists.component';

import { MotDePasseComponent } from './components/dialogs/mot-de-passe/mot-de-passe.component';


import { TranslateHttpLoader } from '@ngx-translate/http-loader';




import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AngularEditorModule } from '@kolkov/angular-editor';



import { GoogleAnalyticsModule, GA_TOKEN } from 'angular-ga';
import { LoadingComponent } from './components/dialogs/utilities/loading-component/loading-component.component';
import { GrantingComponent } from './pages/settings/user/user-component/granting/granting.component';
import { SignatureComponent } from './components/dialogs/signature/signature.component';
import { ChoisirAgentComponent } from './components/dialogs/choisir-agent/choisir-agent.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DelegationComponent } from './components/dialogs/delegation/delegation.component';
import { ImportComponent } from './pages/settings/user/user-component/import/import.component';
import { AuthQrcodeComponent } from './components/dialogs/auth-qrcode/auth-qrcode.component';
import { AddApplicationComponent } from './pages/settings/systeme/systeme-components/add-application/add-application.component';
import { ListApplicationComponent } from './pages/settings/systeme/systeme-components/list-application/list-application.component';
import { AddModulesComponent } from './pages/settings/systeme/systeme-components/add-modules/add-modules.component';
import { ListModulesComponent } from './pages/settings/systeme/systeme-components/list-modules/list-modules.component';
import { AddFonctionsComponent } from './pages/settings/systeme/systeme-components/add-fonctions/add-fonctions.component';
import { ListFonctionsComponent } from './pages/settings/systeme/systeme-components/list-fonctions/list-fonctions.component';


registerLocaleData(localeFr, 'fr-FR');
@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    InfoDialogComponent,
    UserListsComponent,
    MotDePasseComponent,
    GrantingComponent,
    SignatureComponent,
    ChoisirAgentComponent,
    DelegationComponent,
    ImportComponent,
    AuthQrcodeComponent,
    AddApplicationComponent,
    ListApplicationComponent,
    AddModulesComponent,
    ListModulesComponent,
    AddFonctionsComponent,
    ListFonctionsComponent,
 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDialogModule,
    HttpClientModule,
    FontAwesomeModule,
    FormsModule,
   
    // ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
    
    GoogleAnalyticsModule.forRoot(),
    QRCodeModule,
 

  ],

  providers: [ 
      TokenInterceptorProvider,
     { provide: MatDialogRef, useValue: {}}, 
     { provide: MAT_DIALOG_DATA, useValue: {} }, 
    ],
    
  bootstrap: [AppComponent]
})
export class AppModule {

}

export function httpTranslateLoader(http:HttpClient){
  return new TranslateHttpLoader(http)
}
