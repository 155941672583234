import { MatDialog } from "@angular/material/dialog";
import { faWarning, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { InfoDialogComponent } from "../components/dialogs/utilities/info-dialog/info-dialog.component";




export class AppUtilitie{


      // control if string is null
      public static controlString(value: string, message: string): void{
        if(!value || value.length <= 0)
          throw new Error(message);
    }

    public static controlePhoneString(value: string | undefined, message: string, message2: string) {
        if(!value || value == null || value.length <= 0 )
          throw new Error(message);
        else if(value.length != 10)
          throw new Error(message2);
    }

    public static controlNumber(value: number, message: string): void{
      if(!value || value <= 0)
        throw new Error(message);
    }

    static controleEmailString(value: string | undefined, message: string, message2: string) {
        if(!value || value == null || value.length <= 0 )
          throw new Error(message);
        else if(!(value.includes('@')))
          throw new Error(message2);
    }

    static controleConfirmPassword(value: string, confirm: string, confirmMessage: string) {
        if(confirm != value && value.length != confirm.length)
          throw new Error(confirmMessage);
    }

    static rounded(int:number){
      return parseInt(int.toFixed(2))
    }

    static openInfoDialog(dialog: MatDialog, message: string, icon:IconDefinition=faWarning, custom=false, close=true){
      dialog.open(InfoDialogComponent, {
        id:"info-dialog",
        width: "50%",
        height:"35%",
        data: {
          message: message,
          custom,
          icon,
          close
        }
      })
    }

    static purgeNip(nip:string){
      console.log('nip ',nip)
      return parseInt(nip.slice(3,));
    }

    static sortedBythUp(list:any,property:string,isString:boolean=true,subProprety?:string){
      if(isString){
        if(subProprety!=undefined){
          return list.sort((a:any,b:any)=>a[subProprety][property].toLowerCase().charCodeAt()-b[subProprety][property].toLowerCase().charCodeAt());
        }
        else{
          return list.sort((a:any,b:any)=>a[property].toLowerCase().charCodeAt()-b[property].toLowerCase().charCodeAt());
        }
        }
      else{
        if(property=='nip'){

            if(subProprety!=undefined){
              return list.sort((a:any,b:any)=>AppUtilitie.purgeNip(a[subProprety][property])-AppUtilitie.purgeNip(b[subProprety][property]));
            }
            else{
              return list.sort((a:any,b:any)=>AppUtilitie.purgeNip(a[property])-AppUtilitie.purgeNip(b[property]));
            }
        }
        else{
          return list.sort((a:any,b:any)=>a[property]-b[property]);
        }
      }
     }

     static sortedBythDown(list:any,property:string,isString:boolean=true,subProprety?:string){
      if(isString){
        if(subProprety!=undefined){
          return list.sort((a:any,b:any)=>b[subProprety][property].toLowerCase().charCodeAt()-a[subProprety][property].toLowerCase().charCodeAt());
        }
        else{
          return list.sort((a:any,b:any)=>b[property].toLowerCase().charCodeAt()-a[property].toLowerCase().charCodeAt());
        }
        }
      else{
        if(property=='nip'){

            if(subProprety!=undefined){
              return list.sort((a:any,b:any)=>AppUtilitie.purgeNip(b[subProprety][property])-AppUtilitie.purgeNip(a[subProprety][property]));
            }
            else{
              return list.sort((a:any,b:any)=>AppUtilitie.purgeNip(b[property])-AppUtilitie.purgeNip(a[property]));
            }
        }
        else{
          return list.sort((a:any,b:any)=>b[property]-a[property]);
        }

      }
     }

     public static isStrongPassword(value: string): { valid: boolean; message: string } {
      if (value.length < 8) {
        return { valid: false, message: "Le mot de passe doit contenir au moins 8 caractères." };
      }
  
      const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      const capitalCharRegex = /[A-Z]/;
      const lowerCharRegex = /[a-z]/;
      const numberRegex = /[0-9]/;
  
      if (!specialCharRegex.test(value)) {
        return { valid: false, message: "Le mot de passe doit contenir au moins un caractère spécial." };
      }
      if (!capitalCharRegex.test(value)) {
        return { valid: false, message: "Le mot de passe doit contenir au moins une lettre majuscule." };
      }
      if (!lowerCharRegex.test(value)) {
        return { valid: false, message: "Le mot de passe doit contenir au moins une lettre minuscule." };
      }
      if (!numberRegex.test(value)) {
        return { valid: false, message: "Le mot de passe doit contenir au moins un chiffre." };
      }
  
      return { valid: true, message: "Mot de passe fort." };
    }

    public static numberFormat(number: number){
      //const result=  new Intl.NumberFormat("fr-FR",{style: 'currency', currency: 'USD'}).format(number).replace('$US','').replace(',','.')
      //return +(parseFloat(result).toFixed(2).replace(',','.'))
      return new Intl.NumberFormat("fr-FR",{style: 'currency', currency: 'USD'}).format(number).replace('$US','')
    }

    static limit(number:any){
      let taille= number!=null ? number.toString().length : 1;
       if(taille>2){
         let limit=number.toString().replace(/(.{2})(.+)/,"$1");
         return limit
       }
       return !isNaN(number) ? number : 0;
    }


    static lastWeek(){

      const today = new Date();
      let lastWeek: Date | string = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      lastWeek = lastWeek.toLocaleDateString()
      lastWeek = lastWeek.split("/").reverse().join("-");

      return lastWeek;
    }

    static today(){
      const today = new Date().toLocaleDateString().split("/").reverse().join("-");
      return today;
    }

    static todayEn(){
      const today = new Date().toLocaleDateString().split("/").reverse().join("-");
      return today;
    }

    static todayTimestamp(){
      const today = new Date().toLocaleDateString().split("/").reverse().join("-")+" "+ new Date().toLocaleTimeString();
      return today;
    }



    public static print(id: string, style?: string){
      let popupWin: any, printContents: string;
      const section = document.getElementById(`${id}`);
      printContents = section ? section.innerHTML : "";
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=100%');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous">
            <link rel="stylesheet" href="./../../styles.scss">
            <style>
              ${style}
            </style>
          </head>
          <body onload="window.print();">${printContents}</body>

          <script>
          setTimeout(()=>{
           window.close()
          }, 2000)
         </script>
         
        </html>`
      );
      popupWin.document.close();
    }

  
}




