import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "../services/app-config.service";

@Injectable({
  providedIn: 'root'
})

export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private appConfig: AppConfigService
  ){
   
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
   
 
    if(req.url.includes('login')){
      return next.handle(req);
    }

    else if(req.url.includes('from')){
        localStorage.setItem('from',req.url)
    }
       
    const newRequest = req.clone({
      setHeaders: {
        'Authorization': `Bearer ${this.appConfig.getItemFromlocalStorage("token")}`
      }
    });

   

    return next.handle(newRequest);
  }


}

export const TokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true
}
