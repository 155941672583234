import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ExcelEnums } from '../enums/excel.enum';

@Injectable({
    providedIn: 'root',
})

export class ExcelService {

    constructor() { }

    public exportAsExcelFile(json: any[], excelFileName: string): void {

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { 
            Sheets: { 
                "data": worksheet 
            }, 
            SheetNames: ['data'] 
        };

        const excelBuffer: any = XLSX.write(workbook, { 
            bookType: 'xlsx', 
            type: 'array' 
        });

        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        
        const data: Blob = new Blob([buffer], {
            type: ExcelEnums.EXCEL_TYPE
        });

        FileSaver.saveAs(data, fileName + '_export_' + new  Date().toLocaleString() + ExcelEnums.EXCEL_EXTENSION);
    }


    readExcel(file: File): Promise<any[]> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const arrayBuffer = e.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: 'array' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(firstSheet);
            resolve(jsonData);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsArrayBuffer(file); // Utilisation de readAsArrayBuffer
        });
      }
      
}