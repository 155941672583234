<div class="row p-3 mt-3 bg-c shadow-custom bg-white" style="border-radius: 17px !important;"
>
<div class="container">
    <h2 class="mb-4">Importation de données Excel</h2>
    
    <div class="mb-3 col-5">
      <label for="fileUpload" class="form-label">Choisir un fichier Excel :</label>
      <input type="file" id="fileUpload" (change)="onFileChange($event)" accept=".xlsx, .xls" class="form-control" />
    </div>
    
    <table class="table table-bordered table-striped">
        <thead class="table-light">
          <tr>
            <th *ngFor="let key of getHeaders()">{{ key }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data">
            <td *ngFor="let key of getHeaders()">{{ item[key] }}</td>
          </tr>
        </tbody>
      </table>
      
  
    <button (click)="submitData()" class="btn btn-success-custom rounded-2 me-3 " >Charger</button>
  </div>
</div>


  
