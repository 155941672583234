import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { Repository } from "../repository/repository";
import { AppConfigService } from "./app-config.service";
import { Subject } from "rxjs";
import { AppUtilitie } from "../utilities/app-utilitie";




@Injectable({
    providedIn: 'root',
})

export class ConfigService extends Repository <any>{

    fonctions$:Subject<any>=new Subject<any>()
    directions$:Subject<any>=new Subject<any>()
    grades$:Subject<any>=new Subject<any>()

    constructor(
        protected override _http: HttpClient, 
        protected override _router: Router,
        protected override _dialog: MatDialog,
        private appConfig: AppConfigService,
    ) {
        super(_http, `${environment.baseUrl}/config`, _router, _dialog);
    }



    getFonction(uri:string){
        this.appConfig.onStartWaiting()
        
        this.find$(uri).subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()
            this.fonctions$.next(res)
           
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })
    
      }

      getDirection(uri:string){
        this.appConfig.onStartWaiting()
        
        this.find$(uri).subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()
            this.directions$.next(res)
           
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })
    
      }

      getGrade(uri:string){
        this.appConfig.onStartWaiting()
        
        this.find$(uri).subscribe({
          next: (res)=>{
            this.appConfig.onStopWaiting()
            this.grades$.next(res)
           
          },
          error:(error)=>{
            this.appConfig.onStopWaiting()
            AppUtilitie.openInfoDialog(this._dialog, error)
          }
        })
    
      }
}