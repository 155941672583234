import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpDataResponse } from "../utilities/http-data-response";
import { UserLogin } from "../entities/user-login";
import { AppFeeback } from "../enums/app-feedback.enum";

@Injectable({
    providedIn: 'root',
})

export class AuthenticationService{

    constructor(
        protected readonly _http: HttpClient,
    ){}



    public login(user: any): Observable<HttpDataResponse<any>>{
        return this._http.post<HttpDataResponse<any>>(`${environment.baseUrl}/login`, user)
    }

    public auth(user: UserLogin): Observable<any>{

        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          };

        return this._http.post<any>(`${environment.baseUrl}/auth/login`, user, httpOptions)  
    }

    public setup2FA(user: any): Observable<any>{

        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          };

        return this._http.post<any>(`${environment.baseUrl}/auth/setup-2fa`, user, httpOptions)  
    }

    public verifiedOTP(user: any): Observable<any>{

        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          };

        return this._http.post<any>(`${environment.baseUrl}/auth/verify-token`, user, httpOptions)  
    }

    public updatePassword(data: any): Observable<any>{
        return this._http.patch<HttpDataResponse<any>>(`${environment.baseUrl}/auth/update-password`, data).pipe(
            map((res: any) => {
                return res.body;
              }),
              catchError(httpError => {
                console.log(httpError)
                //const {error}=httpError
                console.log('message ', httpError.error.status ,httpError.error.message)

                const errorStatut = httpError.error.status;

                switch(errorStatut){
                    case 404:
                        throw httpError.error.message
                    default:
                         throw httpError.error.message
                }

            })


        )
    }
    
}