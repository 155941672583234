import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { faCheck, faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { async } from 'rxjs';
import { UserSignDto } from 'src/app/entities/sign';
import { AppFeeback } from 'src/app/enums/app-feedback.enum';
import { AppConfigService } from 'src/app/services/app-config.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/users.service';
import { AppUtilitie } from 'src/app/utilities/app-utilitie';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {

  faTime=faTimes;
  urlSignature!:string;
  sign:UserSignDto=new UserSignDto();
  user!:any;
  downloadedFileUrl: string = '';
  user2FA!:any;

  @ViewChild('canvas', { static: true }) canvas!: ElementRef<HTMLCanvasElement>;
  private ctx!: CanvasRenderingContext2D | null;
  painting: boolean = false;
  lines: { startX: number, startY: number, endX: number, endY: number }[] = [];
  private prevX: number = 0;
  private prevY: number = 0;
  imageUrl!: string;
  qrCode: any;

  constructor(

    public appConfig: AppConfigService,
    private userService: UserService,
    private dialog: MatDialog,
    private dialogRef:MatDialogRef<SignatureComponent>,
  ) { }

  ngOnInit(): void {
    this.user = this.appConfig.getItemFromlocalStorage("user");
    this.user = JSON.parse(this.user);
    this.getSignature();
  }

  ngAfterViewInit() {
    if (this.canvas && this.canvas.nativeElement && this.canvas.nativeElement.getContext) {
      this.ctx = this.canvas.nativeElement.getContext('2d');
      this.setupListeners();
    } else {
      alert("Le contexte du canvas n'est pas disponible.");
    }
  }

  async saveSignature() {
    const blobData = await this.captureImage();
    this.imageUrl = this.captureImage64();
    await this.sendFilesFromCloudinary(blobData);
  }

  getSignature() {
    this.userService.get(`agent/signature/${this.user.id}`);
    this.userService.signature$.subscribe(async (res: any) => {
      this.urlSignature = res.data;
      if (this.urlSignature) {
        this.downloadedFileUrl = await this.showDownloadedFile(this.urlSignature);
      }
    });
  }

  isTouchDevice(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0;
  }

  setupListeners(): void {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTouchDevice = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

    if (isTouchDevice || this.isTouchDevice()) {
      this.canvas.nativeElement.addEventListener('touchstart', (e: any) => this.startPosition(e.touches[0]));
      this.canvas.nativeElement.addEventListener('touchend', () => this.endPosition());
      this.canvas.nativeElement.addEventListener('touchmove', (e: any) => {
        e.preventDefault();
        this.draw(e.touches[0]);
      });
    } else {
      this.canvas.nativeElement.addEventListener('mousedown', (e) => this.startPosition(e));
      this.canvas.nativeElement.addEventListener('mouseup', () => this.endPosition());
      this.canvas.nativeElement.addEventListener('mousemove', (e) => this.draw(e));
    }

    this.canvas.nativeElement.addEventListener('dblclick', (e) => this.findAndRemoveLine(e));

  }

  startPosition(e: MouseEvent): void {
    this.painting = true;
    const rect = this.canvas.nativeElement.getBoundingClientRect();
    this.prevX = e.clientX - rect.left;
    this.prevY = e.clientY - rect.top;
    this.draw(e);
  }

  endPosition(): void {
    this.painting = false;
    if (this.ctx) this.ctx.beginPath();
  }

  private draw(e: MouseEvent): void {
    if (!this.ctx || !this.painting) return;

    const rect = this.canvas.nativeElement.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;

    if (this.painting) {
      this.lines.push({ startX: this.prevX, startY: this.prevY, endX: offsetX, endY: offsetY });

      this.ctx.lineWidth = 2;
      this.ctx.lineCap = 'round';
      this.ctx.strokeStyle = '#0000CD';
      this.ctx.lineTo(offsetX, offsetY);
      this.ctx.stroke();
      this.ctx.beginPath();
      this.ctx.moveTo(offsetX, offsetY);
    }

    this.prevX = offsetX;
    this.prevY = offsetY;
  }

  private findAndRemoveLine(e: MouseEvent): void {
    const rect = this.canvas.nativeElement.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const clickY = e.clientY - rect.top;

    const tolerance = 5;

    this.lines = this.lines.filter(line => {
      const distToStart = Math.hypot(line.startX - clickX, line.startY - clickY);
      const distToEnd = Math.hypot(line.endX - clickX, line.endY - clickY);

      return distToStart > tolerance && distToEnd > tolerance;
    });

    this.redrawCanvas();
  }

  private redrawCanvas(): void {
    const canvasElement = this.canvas.nativeElement as HTMLCanvasElement;
    const context = canvasElement.getContext('2d');

    if (context) {
      context.clearRect(0, 0, canvasElement.width, canvasElement.height);

      this.lines.forEach(line => {
        context.beginPath();
        context.moveTo(line.startX, line.startY);
        context.lineTo(line.endX, line.endY);
        context.stroke();
      });
    }
  }

  captureImage(): Promise<Blob> {
    return new Promise((resolve) => {
      this.canvas.nativeElement.toBlob((blob: any) => {
        resolve(blob);
      }, 'image/png');
    });
  }

  captureImage64() {
    return this.canvas.nativeElement.toDataURL();
  }

  async sendUploadedFile(file: any) {
    this.appConfig.onStartWaiting();
    var myHeaders = new Headers();
    myHeaders.append('content-Type', "multipart/form-data");

    var formdata = new FormData();
    formdata.append("file", file, `${this.user.id}-${this.user.prenom}-${this.user.postnom}`);

    var requestOptions: any = {
      method: 'POST',
      body: formdata,
      myHeaders
    };

    try {
      const request = await fetch(`http://10.140.0.106:8002/minio/files/upload`, requestOptions);
      if (request.ok) this.appConfig.onStopWaiting();
      const response = await request.json();
      return response.url;
    } catch (err) {
      this.appConfig.onStopWaiting();
    }
  }

  async showDownloadedFile(key: string): Promise<string> {
    try {
      const url = `http://10.140.0.106:8002/minio/files/${key}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Erreur HTTP! Statut : ${response.status}`);
      }

      const data = await response.json();
      return data.data.src;
    } catch (error) {
      console.error('Erreur lors de la récupération du fichier depuis Minio :', error);
      return '';
    }
  }

  async sendFilesFromCloudinary(file: Blob) {
    const url = await this.sendUploadedFile(file);
    await this.save(url);
  }

  async save(signature: any) {
    this.sign.signature = signature;
    this.userService.update(this.sign, `update/signature/${this.user.id}`);
  
  }

  reset() {
    const canvasElement = this.canvas.nativeElement as HTMLCanvasElement;
    const context = canvasElement.getContext('2d');
    if (context) {
      context.clearRect(0, 0, canvasElement.width, canvasElement.height);
    }
  }
  
}
