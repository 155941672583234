import { IsNotEmpty, IsString, IsOptional } from "class-validator";

export class Application{

    @IsNotEmpty({ message: "Ce champs est obligatoire" })
    @IsString()
    application!: string;

    @IsString()
    @IsOptional()
    description!:string

    @IsOptional()
    basUrl!:string;

    @IsNotEmpty({ message: "Ce champs est obligatoire" })
    @IsString()
    redirectUrl!:string;

    @IsString()
    @IsOptional()
    logo!:string;


}