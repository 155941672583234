import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { InfoDialogComponent } from 'src/app/components/dialogs/utilities/info-dialog/info-dialog.component';
import { User } from 'src/app/entities/agent';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ConfigService } from 'src/app/services/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import { UserService } from 'src/app/services/users.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {

  canBreak!:boolean;

  constructor(
    private userService: UserService,
    public appConfig: AppConfigService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private dialog: MatDialog,
    private router: Router,
    private route:ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  data: any[] = [];


  getHeaders(): string[] {
    return this.data.length > 0 ? Object.keys(this.data[0]) : [];
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.excelService.readExcel(file).then((jsonData) => {
       
        this.data = jsonData.map(item => ({
          ...item
        }));

        console.log('Données importées :', this.data);

      }).catch((error) => {
        console.error('Erreur lors de la lecture du fichier Excel :', error);
      });
    } else {
      console.warn('Aucun fichier sélectionné.');
    }
  }

  async submitData() {
    // Effectuer une requête POST ici avec les données
    console.log('Données à envoyer :', this.data);

    for(let key = 0; key < this.data.length; key++) {
      const data = this.data[key];
      await this.validateBeforeSendData(data);
    
      if ( key==(this.data.length-1) && this.canBreak) {
        this.userService.save(this.data, "/app/settings/menu/users/list-users", "/import");
    
        if(!this.canBreak){
          break; // Sort de la boucle si la condition est remplie
        }
      
      }
    }
    
  }

  async  validateBeforeSendData(data:User){
     
    const canSave= await this.appConfig.validateOrReject(data)
  
      if(canSave==undefined || canSave==true){
        this.canBreak=true;
      } 
      else{
        this.dialog.open(InfoDialogComponent, {
          width: "50%",
          data:{
            message:"Veillez s'il vous plait remplir tout les champs de votre fichier Excel"
          }
        })

        this.canBreak=false;

      }
    }
}
