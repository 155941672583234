import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AppFeeback } from 'src/app/enums/app-feedback.enum';
import { AppConfigService } from 'src/app/services/app-config.service';
import { UserService } from 'src/app/services/users.service';
import { AppUtilitie } from 'src/app/utilities/app-utilitie';
import { ConfirmDialogComponent } from '../utilities/confirm-action-dialog/confirm-dialog.component';

@Component({
  selector: 'app-choisir-agent',
  templateUrl: './choisir-agent.component.html',
  styleUrls: ['./choisir-agent.component.scss']
})
export class ChoisirAgentComponent implements OnInit {
  agent!:any;
  faClose=faTimes;
  users: any[] = new Array();
  agentId!:number;
  message!:string;

  labelInput!:string;
  search!:string;
  isToggleList!:boolean;
  
  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    public appConfig: AppConfigService,
    public router:Router,
    private dialogRef:MatDialogRef<ChoisirAgentComponent>
  ) { }

  ngOnInit(): void {
    this.getListAgents()
  }

  getListAgents(){
    this.userService.get();
    this.userService.users$.subscribe((res:any)=>{
      this.users=res.data;
      console.log('users data ', res)
    })
  }

  selectedAgent(agent:any){
   
    this.agent=agent.prenom.toUpperCase()+" "+agent.nom.toUpperCase()+" "+agent.postnom.toUpperCase();
    this.agentId=agent.id;
    this.isToggleList=!this.isToggleList
  
  }


  toggleList(){
    this.isToggleList=!this.isToggleList
  }

  filteredAgent() {

    console.log('item', this.users, this.search)
    if (this.search) {
      return this.users.filter((value:any) => 
        ( value.nom?.toLowerCase().includes(this.search.toLowerCase()) || value.prenom?.toLowerCase().includes(this.search.toLowerCase()) || value.postnom?.toLowerCase().includes(this.search.toLowerCase()) )
      );
    } else {
      return this.users;
    }
  }


  confirmDelegate(){
    this.dialog.open(ConfirmDialogComponent, {
      data:{
        message:`Voulez-vous vraiment laissé l'agent :  ${this.agent} comme interim ?`
      }
    }).afterClosed().subscribe(res => {
      if(res == "YES"){
         this.dialogRef.close(this.agentId)
      }
    })
  }

  close(){
    this.dialogRef.close()
  }


}
